import React from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

export default class Confetti extends React.Component {
  constructor(props) {
    super(props);
    this.animationInstance = null;
  }

  makeShot = (particleRatio, opts) => {
    this.animationInstance &&
      this.animationInstance({
        ...opts,
        origin: { y: 1 },
        particleCount: Math.floor(500 * particleRatio),
      });
  };

  fire = () => {
    this.makeShot(0.5, {
      spread: 250,
      scalar: 1.25,
      startVelocity: 150,
    });

    this.makeShot(0.5, {
      spread: 150,
      scalar: 1.75,
      startVelocity: 125,
    });

    this.makeShot(0.5, {
      spread: 100,
      scalar: 2.25,
      startVelocity: 100,
    });
  };

  handlerFire = () => {
    this.fire();
    setTimeout(() => this.props.setActive(false), 1000);
  };

  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  render() {
    this?.props?.active && this.handlerFire();
    return (
      <ReactCanvasConfetti
        refConfetti={this.getInstance}
        style={canvasStyles}
      />
    );
  }
}
